import { TaxCategoryService} from '@/modules/tax-category/tax-category-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';

// action types
export const DO_CREATE = 'create';
export const DO_UPDATE = 'update';
export const DO_REMOVE = 'remove';

// mutation types
export const UPDATE_SUCCESS = 'updateSuccess';

const state = {
};

const getters = {
};

const actions = {
    [DO_CREATE](context, values) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'taxCategory/create', { root: true });
            TaxCategoryService.create(values)
                .then((data) => {
                    Message.success(i18n.t('GENERAL.CREATED_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'taxCategory/create', { root: true });
                });
        });
    },
    [DO_UPDATE](context, { id, values }) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'taxCategory/update', { root: true });
            TaxCategoryService.update(id, values)
                .then((record) => {
                    Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                    context.commit('taxCategory/list/updateRecord', { ...record }, { root: true });
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'taxCategory/update', { root: true });
                });
        });
    },
    [DO_REMOVE](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'taxCategory/remove', { root: true });
            TaxCategoryService.remove(id)
                .then(() => {
                    context.commit('taxCategory/list/removeRecord', id, { root: true });
                    Message.success(i18n.t('GENERAL.REMOVE_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'taxCategory/remove', { root: true });
                });
        });
    },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
