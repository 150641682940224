import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export class TaxCategoryPermissions {
    constructor(currentUser) {
        const permissionChecker = new PermissionChecker(
            currentUser,
        );

        this.read = permissionChecker.match(
            Permissions.values.taxCategoryRead,
        );
        this.create = permissionChecker.match(
            Permissions.values.taxCategoryCreate,
        );
        this.edit = permissionChecker.match(
            Permissions.values.taxCategoryEdit,
        );
        this.destroy = permissionChecker.match(
            Permissions.values.taxCategoryDestroy,
        );
    }
}
