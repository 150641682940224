import ApiService from '@/shared/services/api.service';

export class TaxCategoryService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/tax-categories', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/tax-categories', { ...data } )
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/tax-categories/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static async remove(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/tax-categories/${id}`)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }

    static async listAutocomplete() {
        const response = await ApiService.get('/tax-categories?query=status eq active&limit=100');
        return response?.data?.data || [];
    }

    static find(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/tax-categories/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }
}
