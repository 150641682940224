import taxCategoryFormStore from '@/modules/tax-category/store/tax-category-form-store';
import taxCategoryListStore from '@/modules/tax-category/store/tax-category-list-store';

export default {
    namespaced: true,
    modules: {
        form: taxCategoryFormStore,
        list: taxCategoryListStore,
    },
    state: {},
    actions: {},
    mutations: {},
    getters: {},
};
